import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { BlogEntry } from "../../../components";
import { DesktopRightImage, PhoneImage } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The heart of the alps. The country is almost completely covered with
mountains and you find one beautiful valley next to the other, full of
great trails. Well developed, there are many resorts with lifts bikers
can use.`}</p>
    <br />
    <Link className="text-black" to="/destination/switzerland/aletsch" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/aletsch_9.jpg" mdxType="DesktopRightImage" />
        <h2>{`Aletsch`}</h2>
        <h4>{`Top trails next to the biggest glacier of the Alps`}</h4>
        <p>{`The Aletsch glacier with over 20 km length is the biggest of the Alps.
It has its origin high up at famous peaks like the Mönch and Jungfrau.`}</p>
        <PhoneImage src="destinations/aletsch_5.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/switzerland/flimslaax" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/flimslaaxvorab.jpg" mdxType="DesktopRightImage" />
        <h2>{`Flims Laax`}</h2>
        <h4>{`Glaciers and canyons`}</h4>
        <p>{`It's not often that you can ride from the end of the glaciers down to the
river at the bottom of the valley. In Flims Laax you can and even take
the gondola up.`}</p>
        <PhoneImage src="destinations/flimslaaxvorab.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/switzerland/stmoritz" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/stmoritz_8.jpg" mdxType="DesktopRightImage" />
        <h2>{`St. Moritz`}</h2>
        <h4>{`Top of the world`}</h4>
        <p>{`“Another Flow Trail?”. Yes yes, we know. But the Flow Trails in St. Moritz
are different! Really built well, they have an awesome rhythm and get more
fun the more you ride and the faster you get.`}</p>
        <PhoneImage src="destinations/stmoritz_8.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/switzerland/valmuestair" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/valmustair_21.jpg" mdxType="DesktopRightImage" />
        <h2>{`Val Müstair`}</h2>
        <h4>{`Forgotten part of Switzerland`}</h4>
        <p>{`“Quite villages, lonesome trails, alpine adventures, nice people.
The valley of Müstair offers some of the best riding you can imagine,
without any stress of the modern tourism.`}</p>
        <PhoneImage src="destinations/valmustair_21.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/switzerland/zermatt" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/zermatt_4.jpg" mdxType="DesktopRightImage" />
        <h2>{`Zermatt`}</h2>
        <h4>{`Riding in a postcard`}</h4>
        <p>{`Zermatt in Switzerland is surrounded by the high mountains of the main ridge
of the alps. One of the most famous ones, the Matterhorn, always in sight, the
trails wind down from high alpine areas like the over 3.000m high Gornergrat.`}</p>
        <PhoneImage src="destinations/zermatt_4.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      